import { render, staticRenderFns } from "./editDesigners.vue?vue&type=template&id=5f65c372&scoped=true&"
import script from "./editDesigners.vue?vue&type=script&lang=js&"
export * from "./editDesigners.vue?vue&type=script&lang=js&"
import style0 from "./editDesigners.vue?vue&type=style&index=0&id=5f65c372&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f65c372",
  null
  
)

export default component.exports