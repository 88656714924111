var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div'),_c('div',[_c('div',{staticClass:"font-24 mt-10 ml-4"},[_vm._v("Edit Collections")]),_c('div',{staticClass:"justify-center",attrs:{"align":"center"}},[_c('v-card',{staticClass:"mt-2 mx-4"},[_c('div',{staticClass:"panel-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"widget"},[_c('div',{staticClass:"form-elements-sec"},[_c('div',{staticClass:"form-body"},[_c('form',{staticClass:"formPart text-left",attrs:{"name":"details"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm(_vm.id)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"form-group col-6"},[_c('div',[_c('label',{attrs:{"for":"image"}},[_vm._v("Mobile Image:")]),_c('div',{staticClass:"font-12 black--text font-weight-bold"},[_vm._v(" (138 W ✕ 200 H) ")]),_c('v-file-input',{staticClass:"form-control",attrs:{"prepend-icon":"","type":"file","id":"banner-image","placeholder":" Select Image","accept":"image/jpeg, image/png"},on:{"change":_vm.handleMobileImage},model:{value:(_vm.details.imageMobile[0]),callback:function ($$v) {_vm.$set(_vm.details.imageMobile, 0, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"details.imageMobile[0]"}}),(
                                !_vm.$v.details.imageMobile.required && _vm.$v.details.imageMobile.$error
                              )?_c('div',{staticClass:"validation"},[_vm._v(" Mobile Image is required ")]):_vm._e()],1),(_vm.mobileUrl)?_c('div',{staticClass:"preview-image border p-2 mt-3 ml-3"},[_c('v-img',{attrs:{"src":_vm.mobileUrl,"height":"230","width":"100%","contain":""}})],1):_vm._e()]),_c('div',{staticClass:"form-group col-6"},[_c('div',[_c('label',{attrs:{"for":"image"}},[_vm._v(" Desktop Image:")]),_c('div',{staticClass:"font-12 black--text font-weight-bold"},[_vm._v(" (341 W ✕ 480 H) ")]),_c('v-file-input',{staticClass:"form-control",attrs:{"prepend-icon":"","type":"file","id":"banner-image","placeholder":" Select Image","accept":"image/jpeg, image/png"},on:{"change":_vm.handleDesktopImage},model:{value:(_vm.details.image[0]),callback:function ($$v) {_vm.$set(_vm.details.image, 0, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"details.image[0]"}}),(!_vm.$v.details.image.required && _vm.$v.details.image.$error)?_c('div',{staticClass:"validation"},[_vm._v(" Desktop Image is required ")]):_vm._e()],1),(_vm.desktopUrl)?_c('div',{staticClass:"preview-image border p-2 mt-3 mr-3"},[_c('v-img',{attrs:{"src":_vm.desktopUrl,"height":"230","contain":""}})],1):_vm._e()])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"form-group col-6"},[_c('div',[_c('label',{attrs:{"for":"image"}},[_vm._v("Mobile Banner Image:")]),_c('div',{staticClass:"font-12 black--text font-weight-bold"},[_vm._v(" (500 W ✕ 320 H) ")]),_c('v-file-input',{staticClass:"form-control",attrs:{"prepend-icon":"","type":"file","id":"banner-image","placeholder":" Select Image","accept":"image/jpeg, image/png"},on:{"change":_vm.handleMobileBannerImage},model:{value:(_vm.details.mobileBannerImage),callback:function ($$v) {_vm.$set(_vm.details, "mobileBannerImage", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"details.mobileBannerImage"}}),(
                                !_vm.$v.details.mobileBannerImage.required &&
                                _vm.$v.details.mobileBannerImage.$error
                              )?_c('div',{staticClass:"validation"},[_vm._v(" Mobile Banner Image is required ")]):_vm._e()],1),(_vm.mobileBannerUrl)?_c('div',{staticClass:"preview-image border p-2 mt-3 ml-3"},[_c('v-img',{attrs:{"src":_vm.mobileBannerUrl,"height":"230","width":"300px","contain":""}})],1):_vm._e()]),_c('div',{staticClass:"form-group col-6"},[_c('div',[_c('label',{attrs:{"for":"image"}},[_vm._v(" Desktop Banner Image: ")]),_c('div',{staticClass:"font-12 black--text font-weight-bold"},[_vm._v(" (1600 W ✕ 400 H) ")]),_c('v-file-input',{staticClass:"form-control",attrs:{"prepend-icon":"","type":"file","id":"banner-image","placeholder":" Select Image","accept":"image/jpeg, image/png"},on:{"change":_vm.handleDesktopBannerImage},model:{value:(_vm.details.bannerImage),callback:function ($$v) {_vm.$set(_vm.details, "bannerImage", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"details.bannerImage"}}),(
                                !_vm.$v.details.bannerImage.required && _vm.$v.details.bannerImage.$error
                              )?_c('div',{staticClass:"validation"},[_vm._v(" Desktop Banner Image is required ")]):_vm._e()],1),(_vm.desktopBannerUrl)?_c('div',{staticClass:"preview-image border p-2 mt-3 mr-3"},[_c('v-img',{attrs:{"src":_vm.desktopBannerUrl,"height":"230","width":"300px","contain":""}})],1):_vm._e()])]),_c('div',[_c('div',{staticClass:"form-group col"},[_c('label',{attrs:{"for":"link"}},[_vm._v("Palettier SKU Codes: "),_c('v-btn',{on:{"click":_vm.addSku}},[_vm._v("Add")])],1),_c('div',{staticClass:"poppins-medium font-16 pl-4 d-block"},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.details.query),function(item,index){return _c('v-col',{key:index,staticClass:"px-6",attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex"},[(item)?_c('v-text-field',{staticClass:"mt-6 mr-4 px-2",attrs:{"width":"150px","outlined":"","dense":""},model:{value:(item.sku),callback:function ($$v) {_vm.$set(item, "sku", $$v)},expression:"item.sku"}},[_vm._v(_vm._s(item.sku))]):_vm._e(),_c('img',{staticClass:"mt-2",attrs:{"src":require("@/assets/Images/remove.svg"),"alt":"logo"},on:{"click":function($event){return _vm.deleteSKU(index)}}})],1)])}),1)],1)])]),_c('div',{staticClass:"btnPart text-center my-4"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit"}},[_vm._v("Save")]),(_vm.submitStatus === 'OK')?_c('p',{staticClass:"typo__p"},[_vm._v(" Thanks for your submission! ")]):_vm._e(),(_vm.submitStatus === 'ERROR')?_c('p',{staticClass:"typo__p"},[_vm._v(" Please fill the form correctly. ")]):_vm._e(),_c('button',{staticClass:"btn btn-danger mx-3",attrs:{"type":"reset"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(" Cancel ")])])])])])])])])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }