<template>
  <div>
    <div></div>
    <div>
      <div class="font-24 mt-10 ml-4">Edit Collections</div>
      <div class="justify-center" align="center">
        <!-- Top Bar Chart -->
        <v-card class="mt-2 mx-4">
          <div class="panel-content">
            <div class="row">
              <div class="col-md-12">
                <div class="widget">
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form
                        name="details"
                        class="formPart text-left"
                        @submit.prevent="submitForm(id)"
                      >
                        <!-- Name -->
                        <!-- <div>
                          <div class="form-group col">
                            <label for="email">Name:</label>
                            <v-select
                              prepend-icon=""
                              item-text="brandName"
                              v-model="details.name"
                              :items="brands"
                              class="form-control"
                              append-icon="▼"
                            >
                              <template v-slot:selection="{ item }">
                                <v-row no-gutters>
                                  <v-col cols="12">
                                    <div>{{ item.brandName }}</div>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                  <v-list-item-content>
                                    <div>{{ item.brandName }}</div>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                            <div
                              class="validation"
                              v-if="!$v.details.name.required && $v.details.name.$error"
                            >
                              Designer Name is required
                            </div>
                          </div>
                        </div> -->
                        <!-- Email -->
                        <!-- <div>
                          <div class="form-group col">
                            <label for="email">aboutDesigner:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="aboutDesigner"
                              placeholder="aboutDesigner"
                              v-model.trim="details.aboutDesigner"
                              @input="setTouch('aboutDesigner')"
                            />
                            <div
                              class="validation"
                              v-if="
                                !$v.details.aboutDesigner.required &&
                                $v.details.aboutDesigner.$error
                              "
                            >
                              aboutDesigner is required
                            </div>
                          </div>
                        </div> -->
                        <!-- phone no -->
                        <!-- <div>
                          <div class="form-group col">
                            <label for="mobile"> Select Image:</label> <br />
                            <v-file-input
                              prepend-icon=""
                              type="file"
                              class="form-control"
                              id="designer-image"
                              placeholder="Select Image"
                              v-model.trim="details.image[0]"
                              @change="handleImage"
                              accept="image/jpeg, image/png"
                            ></v-file-input>
                          </div>
                          <div class="preview-image border p-2 mt-3 ml-3" v-if="url">
                            <v-img :src="url" height="230" contain></v-img>
                          </div>
                        </div> -->

                        <div class="d-flex">
                          <div class="form-group col-6">
                            <div>
                              <label for="image">Mobile Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (138 W &#10005; 200 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.imageMobile[0]"
                                placeholder=" Select Image"
                                accept="image/jpeg, image/png"
                                @change="handleMobileImage"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="
                                  !$v.details.imageMobile.required && $v.details.imageMobile.$error
                                "
                              >
                                Mobile Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileUrl">
                              <v-img :src="mobileUrl" height="230" width="100%" contain></v-img>
                            </div>
                          </div>

                          <div class="form-group col-6">
                            <div>
                              <label for="image"> Desktop Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (341 W &#10005; 480 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.image[0]"
                                placeholder=" Select Image"
                                @change="handleDesktopImage"
                                accept="image/jpeg, image/png"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="!$v.details.image.required && $v.details.image.$error"
                              >
                                Desktop Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopUrl">
                              <v-img :src="desktopUrl" height="230" contain></v-img>
                            </div>
                          </div>
                        </div>

                        <div class="d-flex">
                          <div class="form-group col-6">
                            <div>
                              <label for="image">Mobile Banner Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (500 W &#10005; 320 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.mobileBannerImage"
                                placeholder=" Select Image"
                                accept="image/jpeg, image/png"
                                @change="handleMobileBannerImage"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="
                                  !$v.details.mobileBannerImage.required &&
                                  $v.details.mobileBannerImage.$error
                                "
                              >
                                Mobile Banner Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileBannerUrl">
                              <v-img
                                :src="mobileBannerUrl"
                                height="230"
                                width="300px"
                                contain
                              ></v-img>
                            </div>
                          </div>

                          <div class="form-group col-6">
                            <div>
                              <label for="image"> Desktop Banner Image: </label>
                              <div class="font-12 black--text font-weight-bold">
                                (1600 W &#10005; 400 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.bannerImage"
                                placeholder=" Select Image"
                                @change="handleDesktopBannerImage"
                                accept="image/jpeg, image/png"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="
                                  !$v.details.bannerImage.required && $v.details.bannerImage.$error
                                "
                              >
                                Desktop Banner Image is required
                              </div>
                            </div>

                            <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopBannerUrl">
                              <v-img
                                :src="desktopBannerUrl"
                                height="230"
                                width="300px"
                                contain
                              ></v-img>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div class="form-group col">
                            <label for="link"
                              >Palettier SKU Codes:
                              <v-btn @click="addSku">Add</v-btn>
                            </label>

                            <div class="poppins-medium font-16 pl-4 d-block">
                              <v-row no-gutters>
                                <v-col
                                  cols="5"
                                  v-for="(item, index) in details.query"
                                  :key="index"
                                  class="px-6"
                                >
                                  <div class="d-flex">
                                    <v-text-field
                                      v-if="item"
                                      v-model="item.sku"
                                      width="150px"
                                      outlined
                                      dense
                                      class="mt-6 mr-4 px-2"
                                      >{{ item.sku }}</v-text-field
                                    >
                                    <img
                                      @click="deleteSKU(index)"
                                      src="@/assets/Images/remove.svg"
                                      alt="logo"
                                      class="mt-2"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </div>
                        <!-- button -->
                        <div class="btnPart text-center my-4">
                          <button type="submit" class="btn btn-success">Save</button>
                          <p class="typo__p" v-if="submitStatus === 'OK'">
                            Thanks for your submission!
                          </p>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'">
                            Please fill the form correctly.
                          </p>
                          <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import homeService from '../../services/homeService';
import imageUploadService from '../../services/imageUploadService';

export default {
  components: {},
  data() {
    return {
      details: {
        imageMobile: [],
        image: [],
        query: [],
        user: '',
        mobileBannerImage: '',
        bannerImage: '',
      },
      submitStatus: false,
      id: '',
      mobileUrl: null,
      desktopUrl: null,
      brands: [],
      mobileBannerUrl: null,
      desktopBannerUrl: null,
    };
  },
  validations: {
    details: {
      image: {
        required,
      },
      imageMobile: {
        required,
      },
      mobileBannerImage: {
        required,
      },
      bannerImage: {
        required,
      },
    },
  },
  async created() {
    this.id = this.$route.params.id;
    if (this.id) {
      await this.getOneDesigner(this.id);
    }
  },
  methods: {
    setTouch(fields) {
      if (fields === 'mobileImage') {
        this.$v.details.imageMobile[0].$touch();
      } else if (fields === 'image') {
        this.$v.details.image[0].$touch();
      } else if (fields === 'mobileBannerImage') {
        this.$v.details.mobileBannerImage.$touch();
      } else if (fields === 'bannerImage') {
        this.$v.details.bannerImage.$touch();
      }
    },
    // this method  is used for save button
    async submitForm(id) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });

        this.submitStatus = 'false';
      } else {
        console.log(id, 'id');
        this.submitStatus = 'true';
        this.details.user = JSON.parse(localStorage.getItem('selectedVendor'));
        const result = await homeService.updateDesignerData(id, this.details);
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Updated Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewCollections');
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
    },
    async getOneDesigner(id) {
      console.log(id, 'id');
      const result = await homeService.getOneDesigner(id);
      this.details.name = await result.data.user.brandName;
      this.details.user = await result.data.user._id;
      console.log(this.details.name, 'brandName');
      //this.details.aboutDesigner = result.data.aboutDesigner;
      this.details.link = result.data.link;
      this.details.image.push(result.data.image[0]);
      this.details.imageMobile.push(result.data.imageMobile[0]);
      this.desktopUrl = this.details.image[0];
      this.mobileUrl = this.details.imageMobile[0];
      this.details.query = result.data.query;
      this.desktopBannerUrl = result.data.bannerImage;
      this.mobileBannerUrl = result.data.mobileBannerImage;

      this.details.bannerImage = result.data.bannerImage;
      this.details.mobileBannerImage = result.data.mobileBannerImage;
    },

    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
        this.$router.push('2');
      });
    },

    async handleDesktopImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.image[0]);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          this.details.image[0] = result.data.meta.location;
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileImage(file) {
      this.mobileUrl = URL.createObjectURL(this.details.imageMobile[0]);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.imageMobile[0] = result.data.meta.location;

          console.log(this.details.imageMobile[0], 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async addSku() {
      const data = {};
      data.sku = '';
      this.details.query.push(data);
    },
    async handleDesktopBannerImage(file) {
      this.desktopBannerUrl = URL.createObjectURL(this.details.bannerImage);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.bannerImage = result.data.meta.location;

          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileBannerImage(file) {
      this.mobileBannerUrl = URL.createObjectURL(this.details.mobileBannerImage);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.mobileBannerImage = result.data.meta.location;

          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    deleteSKU(index) {
      this.details.query.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.validation {
  color: red;
}
.preview-image {
  width: 40%;
  height: 250px;
}
</style>
